.ddw__home-container {
    display:grid;
    grid-template-rows: 3;
}

.ddw__body {
    padding-left: 2rem;
    padding-right: 2rem;
}

@media only screen and (max-width: 768px) {

}