.ddw__projectgroup-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 70%;
  margin-bottom: 7rem;
}

.ddw__projectgroup-content-container {
  display: block;
  width: 40%;
  height: 100%;
}

.ddw__projectgroup-main-picture {
  width: 100%;
  height: 75%;
}

.ddw__projectgroup-main-picture img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.ddw__projectgroup-thumbnails {
  height: 25%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

h2 {
  font-family: "Manrope", sans-serif;
  color: orange;
  margin-bottom: 2rem;
  text-align: center;
}

@media only screen and (max-width: 768px) {

 .ddw__projectgroup-content-container {
  width: 80%;
 }

}
