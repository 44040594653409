.ddw__home-container {
    display: flex;
    background-color: #1a1a1a;
    min-height: 90vh;
    justify-content:center;
}

.ddw__home-welcome {
    display: flex;
    justify-content: center;
    flex-direction: column;
    animation: fadeIn 5s;
    flex: 3;
}

.ddw__home-welcome h1 {
    color:white;
    font-family: 'Manrope', sans-serif;
    font-size: 7rem;
    text-align: center;
}

.ddw__home-welcome p {
    color: white;
    text-align: center;
    font-family: 'Manrope', sans-serif;
}

.ddw__home-picture {
    display:flex;
    height: 20%;
    margin-top:auto;
    margin-bottom: auto;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 768px) {
    .ddw__home-container {
        display:flex;
        min-height:100vh;
        flex-wrap: wrap;
        padding-top: 7rem;
    }

    .ddw__home-welcome {
        display:grid;
        grid-template-columns: 2fr, 1fr;
        max-width:100%;
    }

    .ddw__home-welcome h1 {
        font-size: 5rem;
    }

    .ddw__home-picture {
        display:flex;
        max-width: 100%;
        height: 50%;
    }

    .ddw__home-picture img {
        width:90%;
    }

}

