.ddw__navbar-container {
  display: flex;
  flex-direction: row;
  background: #1a1a1a;
  height: 10vh;
  position: fixed;
  width: 100vw;
  z-index: 1;
}

.ddw__navbar-logo {
  flex: 2;
  cursor: pointer;
}

.ddw__navbar-logo img {
  padding-left: 1rem;
  padding-top: 1rem;
}

.ddw__navbar-links {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  padding-top: 1rem;
}

.ddw__navbar-links a {
  padding: 1rem;
  text-decoration: none;
  color: white;
  font-family: "Manrope", sans-serif;
}

a:hover {
  color: orange;
  text-decoration: underline;
}

a:active {
  color: orange;
  text-decoration: underline;
}

.ddw__nav-btn {
  padding-right: 3rem;
  visibility: hidden;
  opacity: 0;
}

.ddw__navbar-responsive {
  width: 70vw;
  height: 50vh;
}

@media only screen and (max-width: 768px) {
  .ddw__navbar-container {
    height: 15vh;
  }

  .ddw__navbar-links {
    visibility: hidden;
    width: 0%;
  }

  .ddw__nav-btn {
    visibility: visible;
    opacity: 1;
    background: transparent;
    border: none;
    width: 10%;
    font-size: 2rem;
    color: white;
    margin-bottom: 3rem;
    margin-top: 2rem;
    cursor: pointer;
  }

  .ddw__navbar-responsive {
    background-color: grey;
    color: white;
    font-family: "Manrope", sans-serif;
    animation: 1s expand;
  }

  .ddw__navbar-responsive-close {
    text-align: right;
  }

  .ddw__navbar-responsive-close-button {
    border: none;
    background: transparent;
    font-size: 2rem;
    margin-bottom: 3rem;
    margin-right: 1rem;
    margin-top: 2rem;
    color: white;
    height: 10%;
    animation: fadeIn 2s;
  }

  .ddw__navbar-responsive-items {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    flex-direction: column;
  }

  .ddw__navbar-responsive a {
    text-decoration: none;
    color: orange;
    margin: 1rem;
  }
}

@keyframes expand {
  0% {
    height: 0vh;
  }
  100% {
    height: 50vh;
  }
}
