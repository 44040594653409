@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
}

@media only screen and (max-width: 768px) {
  
}