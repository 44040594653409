.ddw__project-item {
  position: relative;
  flex-basis: 25%;
}

.ddw__project-item img {
  backface-visibility: hidden;
  opacity: 1;
  transition: 0.5s ease;
  display: block;
  height: 100%;
  width: 100%;
  padding: 1rem;
}

.ddw__project-item_overlay {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.ddw__project-item:hover .ddw__project-item_overlay {
    opacity: 1;
}

.ddw__project-item img:hover {
    opacity: 0.3;
}

.ddw__project-item_overlay p {
    color: orange;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: "Manrope", sans-serif;
}

  @media only screen and (max-width: 768px) {

    .ddw__project-item {
        height: 25%;
        flex: 1 0 auto;
      }
  }
