.ddw__footer-container {
    height: 50vh;
    background: #1a1a1a;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ddw__footer-top {
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
}

.ddw__footer-top h1 {
    font-family: "Manrope", sans-serif;
    color: #ffa500;
    font-size: 3rem;
    padding: 0;
    margin: 0;
}

.ddw__footer-top p {
    font-family: "Manrope", sans-serif;
    color: white;
    line-height: 2rem;
    font-size: 1rem;
}

@media only screen and (max-width: 768px) {

    .ddw__footer-top h1 {
        font-size: 2rem;
    }
}

