.ddw__projectsoverview-container {
  display: block;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.ddw__projectsoverview-header {
  height: 10vh;
}

.ddw__projectsoverview-content-container {
  width: 100%;
  height: 90%;
  text-align: center;
}

.ddw__projectsoverview-h1 {
  display: flex;
  justify-content: center;
  width: 100%;
}

.ddw__projects {
  height: 100%;
  width: 100%;
}

.ddw__projectsoverview-h1 h1 {
  font-family: "Manrope", sans-serif;
  color: orange;
  font-size: 3rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  width: 100%;
}


@media only screen and (max-width: 768px) {

  .ddw__projectsoverview-h1 h1 {
    font-size: 2rem;
  }

  h1 {
    margin-top: 5rem;
    font-size: 1rem;
  }

}


