.ddw__projectsgroup-thumbnail {
  width: 17%;
  height: 75%;
}

.ddw__projectsgroup-thumbnail img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.ddw__projectsgroup-thumbnail-selected {
  border: solid 5px orange;
}

.ddw__projectsgroup-thumbnail-img:hover {
  border: solid 5px orange;
}

@media only screen and (max-width: 768px) {

  .ddw__projectsgroup-thumbnail {
   height: 50%;
  }
 
 }
