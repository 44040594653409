.ddw__interieur-navbar-container-project {
    display: flex;
    flex-direction: row;
    background: #1a1a1a;
    height: 10vh;
    position: fixed;
    width: 100vw;
}

.ddw__interieur-navbar-logo {
    flex: 2;
}

.ddw__interieur-navbar-logo img {
    padding-left: 1rem;
    padding-top: 1rem;
  }

  @media only screen and (max-width: 768px) {
    .ddw__interieur-navbar-container-project {
        height: 15vh;
    }

  }