.ddw__projects-container {
  display: grid;
  width: 100%;
  grid-template-rows: auto;
  padding-bottom: 4rem;
}

.ddw__projects-container {
  margin-bottom: 2rem;
}

.ddw__projects-title {
  text-align:center;
  margin-bottom: 2rem;
  font-family: "Manrope", sans-serif;
}

.ddw__projects-title h1 {
  font-family: "Manrope", sans-serif;
  color: orange;
  font-size: 3rem;
  margin-top: 2rem;
}

.ddw__projects-title p {
  margin-top: 2rem;
}

.ddw__projects-items-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  overflow: auto;
}

.ddw__projects-more-projects {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}

.ddw__projects-more-projects button {
  background-color: orange;
  padding: 1rem;
  border-radius: 5px;
  width: 10%;
  color: white;
  border-style: none;
  font-family: "Manrope", sans-serif;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {

  .ddw__projects-items-container {
    display: block;
  }

  .ddw__projects-more-projects button {
    width: 50%;
  }

}

