.ddw__aboutme-container {
  display: grid;
  min-height: 50vh;
  grid-template-columns: 65vw, 35vw;
  padding-top: 2rem;
  justify-content: center;
  padding-bottom: 4rem;
  border-bottom: 1px solid grey;
}

.ddw__aboutme-description {
  font-family: "Manrope", sans-serif;
  grid-column-start: 1;
  max-width: 80ch;
}

.ddw__aboutme-description h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: orange;
}

.ddw__aboutme-description p {
  line-height: 1.7rem;
}

.ddw__aboutme-picture {
  grid-column-start: 2;
}

.ddw__aboutme-picture img {
  border-radius: 50%;
  margin-top: 4rem;
}

@media only screen and (max-width: 768px) {

  .ddw__aboutme-container {
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left:1rem;
    max-width: 100%;
    border-bottom: 1px solid gray;
  }

  .ddw__aboutme-picture {
    display:flex;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .ddw__aboutme-picture img {
    max-width: 70%;
  }

  .ddw__aboutme-description p {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }



}